import React from 'react'
import Helmet from 'react-helmet'
import PageHeader from 'components/PageHeader'
import { Grid, Row, Col } from 'react-flexbox-grid'
import { Paragraph } from 'components/Typography'
import styles from './index.module.less'
import Layout from 'components/Layout'
import image1 from 'images/project-19b.png'
import image2 from 'images/project-19c.png'

const Page = () => (
  <Layout>
    <Helmet
      title="The impossible dream"
      meta={[
        {
          name: 'description',
          content: 'Following years of serious ceiling leaks, a Wellington family home had become practically uninhabitable.',
        },
      ]}
    >
      <html lang="en" />
    </Helmet>
    <PageHeader
      title="The impossible dream"
      intro="Following years of serious ceiling leaks, a Wellington family home had become practically uninhabitable."
    />
    <Grid fluid>
      <Row>
        <Col sm={8} className={styles.projectText}>
          <Paragraph>
            Following years of serious ceiling leaks, a Wellington family home had become practically uninhabitable. Part of the ceiling had collapsed and the floor had deteriorated, making it unsafe to walk on and by the time the homeowner reached out to the trust, there was an urgent need for repair.
          </Paragraph>
          <Paragraph>
            This was certainly one of the larger jobs carried out by the Trust in recent years and the first step was to install temporary accommodation for the family &ndash; a cabin onsite, to allow for renovations on the main house. The interior of the house needed complete refurbishment, including the bathroom, laundry and kitchen. All services were checked and replaced where necessary. Some interior wall linings needed to be replaced, which became an issue with the shortage of gib board throughout NZ. We were fortunate that a few good Samaritans came to the party and supplied us with enough gib board to complete the work.
          </Paragraph>
          <Paragraph>
            Because the existing exterior wall cladding was delaminating the team upgraded it with shadowclad, pre-painted by the family. We were fortunate that one of our sponsors&ndash; Metalcraft assisted with the roof and some young people in pretraining helped with laying it.
          </Paragraph>
          <Paragraph>
            Every Saturday morning, extended whānau rolled up their sleeves and helped to redecorate the house. Now the homeowners have moved back in and can enjoy their revamped home with their wider family.
          </Paragraph>
          <blockquote className={styles.quote}>
            &ldquo;...(Since) My parents moved back in, (we) are able to enjoy the benefits of a beautiful house again. Family support was very important to help out with jobs that Ryhaven needed assistance with. I was able to pull together teams to get the work done. Ryhaven were very supportive throughout the process and did an amazing job. Their workmen went over and above (working weekends and outside of normal hours) to get the job done. Words cannot express the gratitude and joy this brought to our family. Huge thanks to everyone involved and all of their sponsors too.&rdquo;
            <br /><br />
            Homeowner
          </blockquote>
        </Col>
        <Col sm={4} className={styles.projectImages}>
          <img className={styles.image} src={image1} alt="" />
          <img className={styles.image} src={image2} alt="" />
        </Col>
      </Row>
    </Grid>
  </Layout>
)

export default Page
